import hash from 'object-hash';
import React, { createContext, useContext } from 'react';
import { PreloadedQuery, usePreloadedQuery, usePaginationFragment } from 'react-relay';
import { KeyTypeData } from 'react-relay/relay-hooks/helpers';
import { ConcreteRequest, OperationType, ReaderFragment } from 'relay-runtime';

import { cloneDeep, get, set } from '@attentive/nodash';

import { getPaginationMetadata } from '../../utils/get-pagination-metadata';
import { RelayConnection, RelayConnectionFields, RelayKeyTypeExtended } from '../../utils/relay';

import { PaginationControlsActionType, createPaginationControls } from './createPaginationControls';
import { getWindowedConnection } from './getWindowedConnection';

const DEFAULT_PAGE_SIZE = 20;

// Needed in order to allow passing relay's readonly data into `set()`
type Writable<T> = {
  -readonly [K in keyof T]: T[K];
};

type CreatePaginationContextArgs = {
  rootQuery: ConcreteRequest;
  refetchableFragment: ReaderFragment;
};

// The entire context is wrapped in a function purely for typing reasons. In
// order to provide the consumer with typing for their `data` returned from gql,
// without asking them to pass us typing info into multiple components, we need
// to create a closure with the typing info at the top-level. This way the
// consumer can provide us their gql typing at a single place and we can
// generate components that are aware of those types. Otherwise there isn't a
// way for TS to infer a component's typing via implicit context and
// parent/child hierarchy.
function createPaginationContext<
  ConcreteQueryType extends OperationType,
  RefetchableQueryType extends OperationType,
  FragmentType extends RelayKeyTypeExtended
>({ rootQuery, refetchableFragment }: CreatePaginationContextArgs) {
  const QueryRefContext = createContext<PreloadedQuery<ConcreteQueryType> | null>(null);
  const useQueryRef = () => {
    const value = useContext(QueryRefContext);
    if (value === null) {
      throw new Error('useQueryRef is not a child of the matching QueryRefContext.Provider');
    }

    return value;
  };

  const [usePaginationControls, PaginationControlsProvider] = createPaginationControls();

  // We separate out the metadata hook from the data hook so we can safely
  // access it without triggering suspense.
  function usePaginationMetadata() {
    const queryRef = useQueryRef();
    const [controls] = usePaginationControls();
    const { paginationMetadata } = getPaginationMetadata(refetchableFragment);
    const pageSize: number = paginationMetadata?.forward
      ? queryRef.variables[paginationMetadata?.forward?.count]
      : DEFAULT_PAGE_SIZE;

    return {
      pageSize,
      pageIndex: controls.pageIndex,
    };
  }

  type UsePaginationDataOpts = {
    windowData?: boolean;
  };
  function usePaginationData(opts: UsePaginationDataOpts = {}) {
    const { windowData = true } = opts;

    const queryRef = useQueryRef();
    const [controls, controlsDispatch] = usePaginationControls();
    const { pageSize } = usePaginationMetadata();

    const refetchableRef = usePreloadedQuery<ConcreteQueryType>(
      rootQuery,
      queryRef
    ) as FragmentType;
    const relayPagination = usePaginationFragment<RefetchableQueryType, FragmentType>(
      refetchableFragment,
      refetchableRef
    );

    const variables = refetchableRef.__fragmentOwner?.variables;
    const { connectionPathInFragmentData } = getPaginationMetadata(refetchableFragment);
    const connection = get(
      relayPagination.data,
      connectionPathInFragmentData?.join('.') || '',
      null
    ) as RelayConnection | null;
    const { PAGE_INFO } = RelayConnectionFields;
    const pageInfo = connection?.[PAGE_INFO] || null;
    const totalCount = connection?.totalCount || 0;

    // Swap out the connection for a windowed version of it
    let data = relayPagination.data;
    if (windowData) {
      const windowedConnection = getWindowedConnection(
        connection,
        controls.pageCursors,
        controls.pageIndex
      );
      data = set(
        cloneDeep(data) as Writable<KeyTypeData<FragmentType>>,
        connectionPathInFragmentData?.join('.') || '',
        windowedConnection
      );
    }

    const internalRefetch: typeof relayPagination.refetch = (...args) => {
      // Relay's `refetch()` fetches the connection from scratch. In other
      // words, we're fetching it again from the beginning and "resetting"
      // our pagination state.
      // See: https://relay.dev/docs/next/guided-tour/list-data/refetching-connections/
      // This means we also need to reset our internal state.
      controlsDispatch({ type: PaginationControlsActionType.RESET });
      return relayPagination.refetch(...args);
    };

    return {
      // Custom fields
      variables,
      totalCount,
      dataKey: hash(data),

      // Relay's standard pagination response
      ...relayPagination,

      // Our custome implementations of relay's pagination api
      data,
      // Because our servers generally don't support backwards pagination, we
      // can't rely on them to tell relay whether there's a previous page.
      // Instead we rely on our local page cache.
      hasPrevious: controls.pageIndex > 0,
      hasNext: relayPagination.hasNext || controls.hasNext,
      // Relay does not synchronize "stateful" fields (isLoadingNext,
      // isLoadingPrevious, etc) of `usePaginationFragment` across multiple
      // calls to it using the same query/fragment. This means if a consumer
      // has multiple components using `usePaginatedData()` and one of them
      // calls `loadNext()`, only one component will properly track the
      // loading state. This is problematic if multiple components should
      // enter a loading state when a new page is being fetched. So, to get
      // around this we track the loading state ourselves and rely on that as
      // a fallback check.
      isLoadingNext: relayPagination.isLoadingNext || controls.isLoadingNext,
      loadNext: () => {
        const isLastCursor = !controls.pageCursors[controls.pageIndex + 1];

        // If we aren't on our last known cursor we know we can freely load
        // the next page we have data for. If we are on our last known page
        // we need to consult relay on whether or not there are any pages left
        // to fetch.
        if (!isLastCursor || (isLastCursor && pageInfo?.hasNextPage)) {
          controlsDispatch({
            type: PaginationControlsActionType.LOAD_NEXT,
            currentCursor: pageInfo?.endCursor as string,
          });
        }

        // Only attempt to fetch the next page if we haven't already, otherwise
        // relay might fetch future pages and we'll lose track of its cursor.
        if (isLastCursor && pageInfo?.hasNextPage) {
          relayPagination.loadNext(pageSize, {
            onComplete: () => {
              controlsDispatch({ type: PaginationControlsActionType.LOADED_NEXT });
            },
          });
        } else {
          // If we didn't trigger an actual network fetch, immediately mark
          // the next load as completed.
          controlsDispatch({ type: PaginationControlsActionType.LOADED_NEXT });
        }
      },
      loadPrevious: () => {
        // Our services generally don't support backwards pagination, so we
        // don't want to actually call relay's `loadPrevious`.
        controlsDispatch({ type: PaginationControlsActionType.LOAD_PREVIOUS });
      },
      refetch: internalRefetch,
    };
  }

  type PaginationContextProviderProps = {
    queryRef: PreloadedQuery<ConcreteQueryType>;
    children: React.ReactNode;
  };
  function PaginationContextProvider({ queryRef, children }: PaginationContextProviderProps) {
    return (
      <QueryRefContext.Provider value={queryRef}>
        <PaginationControlsProvider fetchKey={Number(queryRef.fetchKey)}>
          {children}
        </PaginationControlsProvider>
      </QueryRefContext.Provider>
    );
  }

  return [PaginationContextProvider, usePaginationData, usePaginationMetadata] as const;
}

export { createPaginationContext };
